import {
    Avatar,
    Box,
    Link as ChakraLink,
    Fade,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    useColorMode,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react'
import { Link, useMatches, useSubmit } from '@remix-run/react'
import { BiExit, BiMoon, BiSun, BiUser } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'

import type { CurrentUserJson } from '~/services/auth/auth.server'

import { PHOCUSLogo } from '../lib/svg/PHOCOSLogo'
import { Limiter } from './Limiter'
import { LinkButton } from './LinkButton'

const TopLinkComponent: React.FC<{ title: string; link: string; active?: boolean; w?: string }> = ({
    title,
    link,
    active,
    w,
}) => {
    return (
        <ChakraLink
            fontWeight="bold"
            as={Link}
            to={link}
            w={w ? w : '10rem'}
            p={2}
            rounded="md"
            textAlign="center"
            sx={
                active
                    ? {
                          textDecoration: 'none',
                          backgroundColor: 'purple.500',
                          padding: 2,
                          color: '#fff',
                      }
                    : {}
            }
            _hover={{ textDecoration: 'none', backgroundColor: 'purple.500', padding: 2, color: '#fff' }}
        >
            {title}
        </ChakraLink>
    )
}

interface Props {
    user: CurrentUserJson | null
    canCreate: boolean
}

export function Nav(props: Props) {
    const submit = useSubmit()
    const matches = useMatches()
    const { colorMode, toggleColorMode } = useColorMode()
    const [sm] = useMediaQuery('(max-width: 1200px)')
    const isDark = colorMode === 'dark'

    return (
        <Box
            w="100%"
            pos="fixed"
            top={0}
            left={0}
            borderBottom="1px solid"
            borderColor={useColorModeValue('gray.100', 'gray.700')}
            zIndex={500}
        >
            <Limiter
                display="flex"
                transition="200ms all"
                py={{ base: 4, md: 3 }}
                px={4}
                bg={useColorModeValue('white', 'gray.800')}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
            >
                {/* Left link list */}

                <HStack spacing={0}>
                    <PHOCUSLogo />
                    <TopLinkComponent w="fit-content" title="PHOCUS" link="/" />
                </HStack>

                <HStack spacing={8}>
                    {!sm ? (
                        <>
                            {props.canCreate ? (
                                <TopLinkComponent
                                    title="New Submission"
                                    link="/submit/new"
                                    active={matches.some((match) => match.pathname === '/submit')}
                                />
                            ) : null}
                            <TopLinkComponent title="All Submissions" link="/submit/list" />
                            <TopLinkComponent title="Snapshot" link="/snapshot" />
                            {/* <TopLinkComponent title="PHN Admin" link="/admin/phn" /> */}
                            <TopLinkComponent title="Admin" link="/admin/platform" />
                        </>
                    ) : null}

                    {/* Right link list */}

                    {!props.user && (
                        <Fade in>
                            <HStack spacing={4} display={{ base: 'none', md: 'flex' }}>
                                <LinkButton to="/login" variant="ghost">
                                    Login
                                </LinkButton>
                            </HStack>
                        </Fade>
                    )}

                    {/* Right menu list */}
                    {/* https://github.com/chakra-ui/chakra-ui/issues/3173 */}
                    <Box>
                        <Menu placement="bottom-end" closeOnSelect closeOnBlur>
                            <MenuButton
                                as={IconButton}
                                display={{ base: 'flex', md: props.user ? 'flex' : 'none' }}
                                p={0}
                                colorScheme={props.user ? 'gray' : undefined}
                                borderRadius="full"
                                icon={
                                    props.user ? (
                                        <Avatar
                                            size="sm"
                                            color="black"
                                            boxSize="35px"
                                            bg="purple.50"
                                            // src={createImageUrl(props.user.avatar)}
                                            name={props.user.firstName}
                                        />
                                    ) : (
                                        <Box as={GiHamburgerMenu} />
                                    )
                                }
                            />

                            <MenuList fontSize="md">
                                {props.user ? (
                                    <>
                                        <Link to="/settings">
                                            <MenuItem icon={<Box as={BiUser} boxSize="16px" />}>Settings</MenuItem>
                                        </Link>
                                        {/* {props.user.role === Role.ADMIN && (
                                        <Link to="/admin">
                                            <MenuItem icon={<Box as={BiCog} boxSize="16px" />}>Admin</MenuItem>
                                        </Link>
                                    )} */}
                                        <MenuDivider />
                                        <MenuItem
                                            closeOnSelect={false}
                                            icon={<Box as={isDark ? BiSun : BiMoon} boxSize="16px" />}
                                            onClick={toggleColorMode}
                                        >
                                            {`Switch to ${isDark ? 'light' : 'dark'}`}
                                        </MenuItem>
                                        <MenuDivider />

                                        <MenuItem
                                            onClick={() => submit(null, { method: 'post', action: '/logout' })}
                                            icon={<Box as={BiExit} boxSize="16px" />}
                                        >
                                            Logout
                                        </MenuItem>
                                    </>
                                ) : (
                                    <>
                                        <MenuItem
                                            closeOnSelect={false}
                                            icon={<Box as={isDark ? BiSun : BiMoon} boxSize="16px" />}
                                            onClick={toggleColorMode}
                                        >
                                            Toggle theme
                                        </MenuItem>
                                        <MenuDivider />
                                        <Link to="/login">
                                            <MenuItem>Login</MenuItem>
                                        </Link>
                                    </>
                                )}
                            </MenuList>
                        </Menu>
                    </Box>
                </HStack>
            </Limiter>
        </Box>
    )
}
