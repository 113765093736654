import { Box, useColorMode } from '@chakra-ui/react'

export const PHOCUSLogo = () => {
    const { colorMode } = useColorMode()
    const isDark = colorMode === 'dark'
    return (
        <Box as="svg" role="img" width="22px" height="22px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
                fill={isDark ? 'white' : '#1b1464'}
                d="m10.97,2.77c0-.72-.42-1.15-1.09-1.15-.65,0-1.06.44-1.06,1.15,0,2.86,0,5.72,0,8.58,0,.67.43,1.13,1.04,1.14.68,0,1.11-.43,1.11-1.14,0-1.43,0-2.86,0-4.29,0-1.43,0-2.86,0-4.29Z"
            />
            <path
                fill={isDark ? 'white' : '#1b1464'}
                d="m3.36,3.99c-.34,0-.7,0-1.06,0-.67.01-1.11.45-1.11,1.12,0,2.72,0,5.45,0,8.17,0,.61.51,1.1,1.1,1.1.61,0,1.06-.48,1.07-1.12,0-.98,0-1.96,0-2.94,0-2.09,0-4.18,0-6.33Z"
            />
            <path
                fill={isDark ? 'white' : '#1b1464'}
                d="m6.36,4.03c-.44-.05-.89-.01-1.36-.01v8.42c.4,0,.77,0,1.14,0,.62-.02,1.04-.43,1.04-1.05.01-2.09,0-4.19,0-6.28,0-.54-.35-1.02-.82-1.07Z"
            />
            <path
                fill={isDark ? 'white' : '#1b1464'}
                d="m14.81,5.06c0-.57-.38-1.03-.94-1.09-.39-.05-.8,0-1.23,0,0,.19,0,.31,0,.43,0,2.29,0,4.57,0,6.86,0,.69.45,1.19,1.08,1.19.61,0,1.08-.49,1.09-1.16,0-2.07,0-4.14,0-6.21Z"
            />
        </Box>
    )
}
