import { Box } from '@chakra-ui/react'
import type { LoaderArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import Footer from '../components/Footer'
import { Nav } from '../components/Nav'
import { getUserIfLoggedIn } from '../services/auth/auth.server'
import { submissionCreatable } from '../services/user/submissions.server'

export const loader = async ({ request }: LoaderArgs) => {
    const env = process.env.ENVIRONMENT ?? 'unknown'
    const user = await getUserIfLoggedIn(request)
    const canCreate = await submissionCreatable(user?.id ?? '')
    //if (!user) throw redirect('/login')
    return json({ user, env, canCreate })
}

export default function HomeLayout() {
    const { user, env, canCreate } = useLoaderData<typeof loader>()

    return (
        <>
            <Nav user={user} canCreate={canCreate} />
            <Box
                px={6}
                mt={20}
                maxH="87vh"
                sx={{
                    overflowY: 'scroll',
                }}
            >
                <Outlet />
            </Box>
            <Footer user={user} env={env} />
        </>
    )
}
